import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Polygon, Popup,Marker, LayersControl, Rectangle, Tooltip, WMSTileLayer} from 'react-leaflet';
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import geojsonData from './Components/img/cuenca.json';
import L, { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import Header from './Components/Header';
import lagos from './Components/img/lagosylagunasnanay.json'; 
import rios from './Components/img/riosnanay.json'; 
import minera from './Components/img/minera.json'; 
import minera2 from './Components/img/actmin.json'; 
import maynas from './Components/img/maynasc.json';
import geojsonSalud from './Components/img/estasalud.json';
import logo from './Components/img/salud.png';
import mineriailegallogo from './Components/img/mineriailegal.png';
import comunidadeslogo from './Components/img/comunidadesmineras.png';
import minerialogo from './Components/img/minerialogo.png';
import geojsonColegios from './Components/img/colegiosnanay.json';
import geojsonAnprivada from './Components/img/areadeconservacionprivada.json';
import centrop1 from './Components/img/CENTROP1.json';
import centrop2 from './Components/img/CENTROP2.json';
import mundo from './Components/img/res.json';
import anp1 from './Components/img/anp1.json';
import anp2 from './Components/img/anp2.json';
import anp3 from './Components/img/anp3.json';
import anp4 from './Components/img/anp4.json';
import anp5 from './Components/img/anp5.json';
import monitoreo1 from './Components/img/monitoreo1.json';
import monitoreo2 from './Components/img/monitoreo2.json';
import mineriaIlegal from './Components/img/mineriailegal.json';
import d1 from './Components/img/homicidio.json';
import d2 from './Components/img/violaciondemenordeedad.json';
import d3 from './Components/img/tratadepersonas.json';
import d4 from './Components/img/tocamientosindebidos.json';
import { Sidebar } from 'flowbite-react';
import { HiChartPie } from 'react-icons/hi';
import 'flowbite/dist/flowbite.css'; 
import homi from './Components/img/iconos/homi.png';
import toca from './Components/img/iconos/toca.png';
import trata from './Components/img/iconos/trata.png';
import viol from './Components/img/iconos/viol.png';
import VisitorInfo from './Info';
const apiUrl = process.env.REACT_APP_API_URL;
const apiMaps = process.env.REACT_APP_GOOGLE_MAPS;

const center = {
  lat: -3.231361, 
  lng: -74.301128,
};

const districtColors = [
  '#DFFFD9',
  '#AEE4AE',
  '#7ECC8E',
  '#57D957',
  '#30C030',
  '#0A7B0D'
];
const customIcon = new Icon({
  iconUrl: logo,
  iconSize: [35, 35],

});

const customIconm = new Icon({
  iconUrl: minerialogo,
  iconSize: [60, 35],

});

const customIconmi = new Icon({
  iconUrl: mineriailegallogo,
  iconSize: [40, 40],

});

const customIcomunidades = new Icon({
  iconUrl: comunidadeslogo,
  iconSize: [20, 20],

});

const homiicon = new Icon({
  iconUrl: homi,
  iconSize: [20, 20],

});
const tocaicon = new Icon({
  iconUrl: toca,
  iconSize: [20, 20],

});

const trataicon = new Icon({
  iconUrl: trata,
  iconSize: [20, 20],

});

const violicon = new Icon({
  iconUrl: viol,
  iconSize: [20, 20],

});

const Visorcuencananay = () => {

  /* ESTILOS DEL MAPA */
  const googleApiKey = apiMaps; // Reemplaza con tu clave de API de Google Maps
  const mapStyles = {
    Aubergine: require("./Stylemaps/aubergine-map-style.json"),
    Dark: require("./Stylemaps/dark-map-style.json"),
    Retro: require("./Stylemaps/retro-map-style.json"),
    Night: require("./Stylemaps/night-map-style.json"),
    
    Estandar: require("./Stylemaps/standard-map-style.json"),
  };

  const [selectedStyle, setSelectedStyle] = useState("Aubergine");

  const handleStyleChange = (newStyle) => {
    setSelectedStyle(newStyle);
  };
  /* ESTILOS DEL MAPA */

  /* COORDENADAS */
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [coordinatesrios, setCoordinatesrios] = useState([]);
  const [coordinateslagos, setCoordinateslagos] = useState([]);
  const [coordinatesminera, setCoordinatesminera] = useState([]);
  const [coordinatesSalud, setCoordinatesSalud] = useState([]);
  const [coordinatesMinera, setCoordinatesMinera] = useState([]);
  const [coordinatesm, setCoordinatesm] = useState([]);
  const [coordinatesc, setCoordinatesc] = useState([]);
  const [coordinatesanprivada, setCoordinatesanprivada] = useState([]);
  const [coordinatesanp1, setCoordinatesanp1] = useState([]);
  const [coordinatesanp2, setCoordinatesanp2] = useState([]);
  const [coordinatesanp3, setCoordinatesanp3] = useState([]);
  const [coordinatesanp4, setCoordinatesanp4] = useState([]);
  const [coordinatesanp5, setCoordinatesanp5] = useState([]);
  const [coordinatesMineriaIlegal, setCoordinatesMineriaIlegal] = useState([]);
  const [coordinatesd1, setCoordinatesd1] = useState([]);
  const [coordinatesd2, setCoordinatesd2] = useState([]);
  const [coordinatesd3, setCoordinatesd3] = useState([]);
  const [coordinatesd4, setCoordinatesd4] = useState([]);
  const [coordinatescentrop1, setCoordinatescentrop1] = useState([]);
  const [coordinatescentrop2, setCoordinatescentrop2] = useState([]);

  const [coordinatesmonitoreo1, setCoordinatesmonitoreo1] = useState([]);
  const [coordinatesmonitoreo2, setCoordinatesmonitoreo2] = useState([]);
  const [coordinatesmundo, setCoordinatesmundo] = useState([]);
  /* COORDENADAS */

  /* SELECCIÓN DE OPCIONES MENÚ */
  const [selectedDistricts, setSelectedDistricts] = useState(['TODO']);
  const [selectedShapes, setSelectedShapes] = useState([]);
  const [selectedInfrapublica, setSelectedInfrapublica] = useState([]);
  const [selectedMineria, setSelectedMineria] = useState([]);
  const [selectedDelitos, setSelectedDelitos] = useState([]);
  const [selectedExtra, setSelectedExtra] = useState([]);
  const [selectedMonitoreo, setSelectedMonitoreo] = useState([]);
  /* SELECCIÓN DE OPCIONES MENÚ */

  /* Delimitaciones Y OPCIONES DE MENU */
  const [districtNames, setDistrictNames] = useState(['TODO']);
  const [shapeNames, setShapeNames]=useState(['Rios','Lagunas']);
  const [extraNames, setextraNames]=useState(['Centros poblados','ANP']);
  const [infrapublicaNames, setinfrapublicaNames]=useState(['Postas médicas','Instituciones educativas']);
  const [mineriaNames, setmineriaNames]=useState(['Concesión otorgada','Comunidades con actividad','Dragas intervenidas']);
  const [delitosNames, setdelitosNames]=useState(['Homicidios','Violaciones','Trata de personas','Tocamientos']);
  const [monitoreoNames, setmonitoreoNames]=useState(['Temperatura','Precipitación','Incendio','Cobertura forestal']);
  
  
  /* MANEJO DE OPCIONES DE ELECCIÓN MENU */
  const handleShapeChange = (shape, isChecked) => {
    if (isChecked) {
      setSelectedShapes(prev => [...prev, shape]);
    } else {
      setSelectedShapes(prev => prev.filter(s => s !== shape));
    }
  };

  const handleInfraChange = (shape, isChecked) => {
    if (isChecked) {
      setSelectedInfrapublica(prev => [...prev, shape]);
    } else {
      setSelectedInfrapublica(prev => prev.filter(s => s !== shape));
    }
  };

  const handleMineriaChange = (shape, isChecked) => {
    if (isChecked) {
      setSelectedMineria(prev => [...prev, shape]);
    } else {
      setSelectedMineria(prev => prev.filter(s => s !== shape));
    }
  };

  const handleDelitosChange = (shape, isChecked) => {
    if (isChecked) {
      setSelectedDelitos(prev => [...prev, shape]);
    } else {
      setSelectedDelitos(prev => prev.filter(s => s !== shape));
    }
  };

  const handleExtraChange = (shape, isChecked) => {
    if (isChecked) {
      setSelectedExtra(prev => [...prev, shape]);
    } else {
      setSelectedExtra(prev => prev.filter(s => s !== shape));
    }
  };  

  const handleMonitoreoChange = (shape, isChecked) => {
    if (isChecked) {
      setSelectedMonitoreo(prev => [...prev, shape]);
    } else {
      setSelectedMonitoreo(prev => prev.filter(s => s !== shape));
    }
  };  

  const handleDistrictChange = (district, isChecked) => {
    if (district === 'TODO' && isChecked) {
      setSelectedDistricts(['TODO']);
    } else if (isChecked) {
      setSelectedDistricts(prev => [...prev.filter(d => d !== 'TODO'), district]);
    } else {
      setSelectedDistricts(prev => prev.filter(d => d !== district));
    }
  };
  /* MANEJO DE OPCIONES DE ELECCIÓN MENU */

  /* TRATAMIENTO O PROCESAMIENTO DE SHPAES  */
        useEffect(() => {
          if (maynas.features && Array.isArray(maynas.features)) {
            const uniqueDistricts = Array.from(
              new Set(maynas.features.map(feature => feature.properties.NOMBDIST))
            );
            setDistrictNames(prevState => ['TODO', ...uniqueDistricts]);
          }
        }, []);
        /* EJEMPLO CON PUNTOS */


        useEffect(()=>{
          if (
            geojsonColegios.features &&
            Array.isArray(geojsonColegios.features) &&
            geojsonColegios.features.length > 0
          ) {
            const saludData = geojsonColegios.features
              .filter((feature) => feature.geometry && feature.geometry.type === 'Point')
              .map((feature) => {
                const coordinates = feature.geometry.coordinates;
                const properties = feature.properties;
                const { NOMCPSIG} = properties;
          
                return {
                  coordinates,
                  NOMCPSIG,
            
                };
              });
          
            if (Array.isArray(saludData) && saludData.length > 0) {
              setCoordinatesc(saludData);
            } else {
              setCoordinatesc([]);
            }
          } else {
            setCoordinatesc([]);
          }

        },[])

        useEffect(()=>{
          if (
            geojsonSalud.features &&
            Array.isArray(geojsonSalud.features) &&
            geojsonSalud.features.length > 0
          ) {
            const saludData = geojsonSalud.features
              .filter((feature) => feature.geometry && feature.geometry.type === 'Point')
              .map((feature) => {
                const coordinates = feature.geometry.coordinates;
                const properties = feature.properties;
                const { Nombre, Clasificac, Tipo } = properties;
          
                return {
                  coordinates,
                  Nombre,
                  Clasificac,
                  Tipo,
                };
              });
          
            if (Array.isArray(saludData) && saludData.length > 0) {
              setCoordinatesSalud(saludData);
            } else {
              setCoordinatesSalud([]);
            }
          } else {
            setCoordinatesSalud([]);
          }

        },[])

        useEffect(()=>{
          if (
            minera2.features &&
            Array.isArray(minera2.features) &&
            minera2.features.length > 0
          ) {
            const saludData = minera2.features
              .filter((feature) => feature.geometry && feature.geometry.type === 'Point')
              .map((feature) => {
                const coordinates = feature.geometry.coordinates;
                
        
          
                return {
                  coordinates
                };
              });
          
            if (Array.isArray(saludData) && saludData.length > 0) {
              setCoordinatesMinera(saludData);
            } else {
              setCoordinatesMinera([]);
            }
          } else {
            setCoordinatesMinera([]);
          }

        },[])

        useEffect(() => {
          if (maynas.features && Array.isArray(maynas.features) && maynas.features.length > 0) {
            const allPolygons = maynas.features.filter(feature => feature.geometry && feature.geometry.type === 'MultiPolygon')
                .map(feature => feature.geometry.coordinates);
            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
                setCoordinatesm(allPolygons);
            } else {
                setCoordinatesm([]);
            }
          } else {
            setCoordinatesm([]);
          }
        }, []);

        useEffect(() => {
          if (
            centrop1.features &&
            Array.isArray(centrop1.features) &&
            centrop1.features.length > 0
          ) {
            const allPolygons = centrop1.features
              .filter(
                (feature) => feature.geometry && feature.geometry.type === 'MultiPolygon'
              )
              .map((feature) => feature.geometry.coordinates);

            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
              setCoordinatescentrop1(allPolygons);
            } else {
              setCoordinatescentrop1([]);
            }
          } else {
            setCoordinatescentrop1([]);
          }
        }, []); 
        useEffect(() => {
          if (
            centrop2.features &&
            Array.isArray(centrop2.features) &&
            centrop2.features.length > 0
          ) {
            const allPolygons = centrop2.features
              .filter(
                (feature) => feature.geometry && feature.geometry.type === 'MultiPolygon'
              )
              .map((feature) => feature.geometry.coordinates);

            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
              setCoordinatescentrop2(allPolygons);
            } else {
              setCoordinatescentrop2([]);
            }
          } else {
            setCoordinatescentrop2([]);
          }
        }, []); 

        useEffect(() => {
          if (
            geojsonData.features &&
            Array.isArray(geojsonData.features) &&
            geojsonData.features.length > 0
          ) {
            const allPolygons = geojsonData.features
              .filter(
                (feature) => feature.geometry && feature.geometry.type === 'MultiPolygon'
              )
              .map((feature) => feature.geometry.coordinates);

            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
              setCoordinates(allPolygons);
            } else {
              setCoordinates([]);
            }
          } else {
            setCoordinates([]);
          }
        }, []); 

        useEffect(() => {
          if (
            mundo.features &&
            Array.isArray(mundo.features) &&
            mundo.features.length > 0
          ) {
            const allPolygons = mundo.features
              .filter(
                (feature) => feature.geometry && feature.geometry.type === 'MultiPolygon'
              )
              .map((feature) => feature.geometry.coordinates);

            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
              setCoordinatesmundo(allPolygons);
            } else {
              setCoordinatesmundo([]);
            }
          } else {
            setCoordinatesmundo([]);
          }
        }, []); 

        useEffect(() => {
          if (
            rios.features &&
            Array.isArray(rios.features) &&
            rios.features.length > 0
          ) {
            const allPolygons = rios.features
              .filter(
                (feature) => feature.geometry && feature.geometry.type === 'MultiPolygon'
              )
              .map((feature) => feature.geometry.coordinates);

            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
              setCoordinatesrios(allPolygons);
            } else {
              setCoordinatesrios([]);
            }
          } else {
            setCoordinatesrios([]);
          }
        }, []);

        useEffect(() => {
          if (
            lagos.features &&
            Array.isArray(lagos.features) &&
            lagos.features.length > 0
          ) {
            const allPolygons = lagos.features
              .filter(
                (feature) => feature.geometry && feature.geometry.type === 'MultiPolygon'
              )
              .map((feature) => feature.geometry.coordinates);

            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
              setCoordinateslagos(allPolygons);
            } else {
              setCoordinateslagos([]);
            }
          } else {
            setCoordinateslagos([]);
          }
        }, []);

        useEffect(() => {
          if (
            minera.features &&
            Array.isArray(minera.features) &&
            minera.features.length > 0
          ) {
            const allPolygons = minera.features
              .filter(
                (feature) => feature.geometry && feature.geometry.type === 'MultiPolygon'
              )
              .map((feature) => feature.geometry.coordinates);

            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
              setCoordinatesminera(allPolygons);
            } else {
              setCoordinatesminera([]);
            }
          } else {
            setCoordinatesminera([]);
          }
        }, []);

        useEffect(() => {
          if (geojsonAnprivada.features && Array.isArray(geojsonAnprivada.features) && geojsonAnprivada.features.length > 0) {
            const allPolygons = geojsonAnprivada.features.filter(feature => feature.geometry && feature.geometry.type === 'MultiPolygon')
                .map(feature => feature.geometry.coordinates);
            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
                setCoordinatesanprivada(allPolygons);
            } else {
                setCoordinatesanprivada([]);
            }
          } else {
            setCoordinatesanprivada([]);
          }
        }, []);

        useEffect(() => {
          if (anp1.features && Array.isArray(anp1.features) && anp1.features.length > 0) {
            const allPolygons = anp1.features.filter(feature => feature.geometry && feature.geometry.type === 'MultiPolygon')
                .map(feature => feature.geometry.coordinates);
            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
                setCoordinatesanp1(allPolygons);
            } else {
                setCoordinatesanp1([]);
            }
          } else {
            setCoordinatesanp1([]);
          }
        }, []);

        useEffect(() => {
          if (anp2.features && Array.isArray(anp2.features) && anp2.features.length > 0) {
            const allPolygons = anp2.features.filter(feature => feature.geometry && feature.geometry.type === 'MultiPolygon')
                .map(feature => feature.geometry.coordinates);
            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
                setCoordinatesanp2(allPolygons);
            } else {
                setCoordinatesanp2([]);
            }
          } else {
            setCoordinatesanp2([]);
          }
        }, []);
        
        useEffect(() => {
          if (anp3.features && Array.isArray(anp3.features) && anp3.features.length > 0) {
            const allPolygons = anp3.features.filter(feature => feature.geometry && feature.geometry.type === 'MultiPolygon')
                .map(feature => feature.geometry.coordinates);
            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
                setCoordinatesanp3(allPolygons);
            } else {
                setCoordinatesanp3([]);
            }
          } else {
            setCoordinatesanp3([]);
          }
        }, []);

        useEffect(() => {
          if (anp4.features && Array.isArray(anp4.features) && anp4.features.length > 0) {
            const allPolygons = anp4.features.filter(feature => feature.geometry && feature.geometry.type === 'MultiPolygon')
                .map(feature => feature.geometry.coordinates);
            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
                setCoordinatesanp4(allPolygons);
            } else {
                setCoordinatesanp4([]);
            }
          } else {
            setCoordinatesanp4([]);
          }
        }, []);

        useEffect(() => {
          if (anp5.features && Array.isArray(anp5.features) && anp5.features.length > 0) {
            const allPolygons = anp5.features.filter(feature => feature.geometry && feature.geometry.type === 'MultiPolygon')
                .map(feature => feature.geometry.coordinates);
            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
                setCoordinatesanp5(allPolygons);
            } else {
                setCoordinatesanp5([]);
            }
          } else {
            setCoordinatesanp5([]);
          }
        }, []);

        useEffect(()=>{
          if (
            mineriaIlegal.features &&
            Array.isArray(mineriaIlegal.features) &&
            mineriaIlegal.features.length > 0
          ) {
            const saludData = mineriaIlegal.features
              .filter((feature) => feature.geometry && feature.geometry.type === 'Point')
              .map((feature) => {
                const coordinates = feature.geometry.coordinates;
                const properties = feature.properties;
                const { Nombre, Clasificac, Tipo } = properties;
          
                return {
                  coordinates,
                  Nombre,
                  Clasificac,
                  Tipo,
                };
              });
          
            if (Array.isArray(saludData) && saludData.length > 0) {
              setCoordinatesMineriaIlegal(saludData);
            } else {
              setCoordinatesMineriaIlegal([]);
            }
          } else {
            setCoordinatesMineriaIlegal([]);
          }

        },[])

        useEffect(()=>{
          if (
            d1.features &&
            Array.isArray(d1.features) &&
            d1.features.length > 0
          ) {
            const saludData = d1.features
              .filter((feature) => feature.geometry && feature.geometry.type === 'Point')
              .map((feature) => {
                const coordinates = feature.geometry.coordinates;
                const properties = feature.properties;
                const { Comunidad, Descripcion} = properties;
          
                return {
                  coordinates,
                  Comunidad,
                  Descripcion
                  
                };
              });
          
            if (Array.isArray(saludData) && saludData.length > 0) {
              setCoordinatesd1(saludData);
            } else {
              setCoordinatesd1([]);
            }
          } else {
            setCoordinatesd1([]);
          }

        },[])

        useEffect(()=>{
          if (
            d2.features &&
            Array.isArray(d2.features) &&
            d2.features.length > 0
          ) {
            const saludData = d2.features
              .filter((feature) => feature.geometry && feature.geometry.type === 'Point')
              .map((feature) => {
                const coordinates = feature.geometry.coordinates;
                const properties = feature.properties;
                const { Comunidad, Descripcion} = properties;
          
                return {
                  coordinates,
                  Comunidad,
                  Descripcion
                  
                };
              });
          
            if (Array.isArray(saludData) && saludData.length > 0) {
              setCoordinatesd2(saludData);
            } else {
              setCoordinatesd2([]);
            }
          } else {
            setCoordinatesd2([]);
          }

        },[])

        useEffect(()=>{
          if (
            d3.features &&
            Array.isArray(d3.features) &&
            d3.features.length > 0
          ) {
            const saludData = d3.features
              .filter((feature) => feature.geometry && feature.geometry.type === 'Point')
              .map((feature) => {
                const coordinates = feature.geometry.coordinates;
                const properties = feature.properties;
                const { Comunidad, Descripcion} = properties;
          
                return {
                  coordinates,
                  Comunidad,
                  Descripcion
                  
                };
              });
          
            if (Array.isArray(saludData) && saludData.length > 0) {
              setCoordinatesd3(saludData);
            } else {
              setCoordinatesd3([]);
            }
          } else {
            setCoordinatesd3([]);
          }

        },[])

        useEffect(()=>{
          if (
            d4.features &&
            Array.isArray(d4.features) &&
            d4.features.length > 0
          ) {
            const saludData = d4.features
              .filter((feature) => feature.geometry && feature.geometry.type === 'Point')
              .map((feature) => {
                const coordinates = feature.geometry.coordinates;
                const properties = feature.properties;
                const { Comunidad, Descripcion} = properties;
          
                return {
                  coordinates,
                  Comunidad,
                  Descripcion
                  
                };
              });
          
            if (Array.isArray(saludData) && saludData.length > 0) {
              setCoordinatesd4(saludData);
            } else {
              setCoordinatesd4([]);
            }
          } else {
            setCoordinatesd4([]);
          }

        },[])


        /* POLIGONOS MONITOREO */
        useEffect(() => {
          if (
            monitoreo1.features &&
            Array.isArray(monitoreo1.features) &&
            monitoreo1.features.length > 0
          ) {
            const allPolygons = monitoreo1.features
              .filter(
                (feature) => feature.geometry && feature.geometry.type === 'MultiPolygon'
              )
              .map((feature) => feature.geometry.coordinates);

            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
              setCoordinatesmonitoreo1(allPolygons);
            } else {
              setCoordinatesmonitoreo1([]);
            }
          } else {
            setCoordinatesmonitoreo1([]);
          }
        }, []);

        useEffect(() => {
          if (
            monitoreo2.features &&
            Array.isArray(monitoreo2.features) &&
            monitoreo2.features.length > 0
          ) {
            const allPolygons = monitoreo2.features
              .filter(
                (feature) => feature.geometry && feature.geometry.type === 'MultiPolygon'
              )
              .map((feature) => feature.geometry.coordinates);

            if (Array.isArray(allPolygons) && allPolygons.length > 0) {
              setCoordinatesmonitoreo2(allPolygons);
            } else {
              setCoordinatesmonitoreo2([]);
            }
          } else {
            setCoordinatesmonitoreo2([]);
          }
        }, []);

  return (
    <>
    <Header/>
    <VisitorInfo page="VISOR CUENCA"></VisitorInfo>
    <div className="row">
        <div className="col-2" style={{ maxHeight: '750px', overflowY: 'auto' }}>
          
          <Sidebar aria-label="Sidebar with multi-level dropdown example" label="Hola">
            <Sidebar.Items label="Hola">
              <Sidebar.ItemGroup label="Hola">
                <Sidebar.Collapse
                  icon={HiChartPie}
                  label="Delimitaciones"
                  isOpen={isCollapseOpen}
                >
                  <Sidebar.Item>
                    <p>Límites distritales</p>
                    {districtNames.map(district => (
                      <div key={district}>
                        <label htmlFor="checkbox1">
                          <input
                            type="checkbox"
                            id="checkbox1"
                            name={district}
                            onChange={e => handleDistrictChange(e.target.name, e.target.checked)}
                            checked={selectedDistricts.includes(district)}
                          />
                          {district}
                        </label>
                      </div>
                    ))}
                  </Sidebar.Item>
                  <Sidebar.Item>
                  {extraNames.map(district => (
                      <div key={district}>
                        <label htmlFor="checkbox1">
                          <input
                            type="checkbox"
                            id="checkbox1"
                            name={district}
                            onChange={e => handleExtraChange(e.target.name, e.target.checked)}
                            checked={selectedExtra.includes(district)}
                          />
                          {district}
                        </label>
                      </div>
                    ))}
                    </Sidebar.Item>


                </Sidebar.Collapse>

                <Sidebar.Collapse
                  icon={HiChartPie}
                  label="Recursos hídricos:"
                  isOpen={isCollapseOpen}
                >
                  <Sidebar.Item>
                  
                  {shapeNames.map(shapes => (
                    <div key={shapes}>
                      <label htmlFor='checkbox1'>
                        <input
                          type="checkbox" id="checkbox1"
                          name={shapes}
                          onChange={e => handleShapeChange(e.target.name, e.target.checked)}
                          checked={selectedShapes.includes(shapes)}
                        />
                        {shapes}
                      </label>
                    </div>
                  ))}
                  </Sidebar.Item>



                </Sidebar.Collapse>

                <Sidebar.Collapse
                  icon={HiChartPie}
                  label="Infraestructura pública:"
                  isOpen={isCollapseOpen}
                >
                  <Sidebar.Item>  


                  {infrapublicaNames.map(shapes => (
                    <div key={shapes}>
                      <label htmlFor='checkbox1'>
                        <input
                          type="checkbox" id="checkbox1"
                          name={shapes}
                          onChange={e => handleInfraChange(e.target.name, e.target.checked)}
                          checked={selectedInfrapublica.includes(shapes)}
                        />
                        {shapes}
                      </label>
                    </div>
                  ))}
                  </Sidebar.Item>



                </Sidebar.Collapse>

                <Sidebar.Collapse
                  icon={HiChartPie}
                  label="Minería aúrifera:"
                  isOpen={isCollapseOpen}
                >
                  <Sidebar.Item>  

                  {mineriaNames.map(shapes => (
                    <div key={shapes}>
                      <label htmlFor='checkbox1'>
                        <input
                          type="checkbox" id="checkbox1"
                          name={shapes}
                          onChange={e => handleMineriaChange(e.target.name, e.target.checked)}
                          checked={selectedMineria.includes(shapes)}
                        />
                        {shapes}
                      </label>
                    </div>
                  ))}
                  </Sidebar.Item>



                </Sidebar.Collapse>

                <Sidebar.Collapse
                  icon={HiChartPie}
                  label="Delitos:"
                  isOpen={isCollapseOpen}
                >
                  <Sidebar.Item>  

                  {delitosNames.map(shapes => (
                    <div key={shapes}>
                      <label>
                        <input
                          type="checkbox"
                          name={shapes}
                          onChange={e => handleDelitosChange(e.target.name, e.target.checked)}
                          checked={selectedDelitos.includes(shapes)}
                        />
                        {shapes}
                      </label>
                    </div>
                  ))}
                  </Sidebar.Item>





                </Sidebar.Collapse>
                <Sidebar.Collapse
                  icon={HiChartPie}
                  label="Monitoreo ambiental:"
                  isOpen={isCollapseOpen}
                >
                  <Sidebar.Item>
                  
                  {monitoreoNames.map(shapes => (
                    <div key={shapes}>
                      <label htmlFor='checkbox1'>
                        <input
                          type="checkbox" id="checkbox1"
                          name={shapes}
                          onChange={e => handleMonitoreoChange(e.target.name, e.target.checked)}
                          checked={selectedMonitoreo.includes(shapes)}
                        />
                        {shapes}
                      </label>
                    </div>
                  ))}
                  </Sidebar.Item>



                </Sidebar.Collapse>

            

              
              
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </Sidebar>

        </div>
        

        <div className="col-10">

        <MapContainer center={center} zoom={9} style={{ width: '100%', height: '750px' }}>   





<LayersControl position="topright">
  {Object.keys(mapStyles).map((style) => (
    <LayersControl.BaseLayer
      key={style}
      checked={style === selectedStyle}
      name={style + " Map"}
    >

      <ReactLeafletGoogleLayer
        apiKey={googleApiKey}
        type="roadmap"
        styles={mapStyles[style]}
      />
      {selectedMonitoreo.includes('Incendio') && (
               <WMSTileLayer
               layers="geonode:_01_obs_nanay_incendios_2019"
               version="1.1.0"
               opacity={1}
               transparent
               url="https://maps.oraotca.org/geoserver/geonode/wms?"
               srs="EPSG:4326"
               format="image/png"
             />

      )}
            {selectedMonitoreo.includes('Incendio') && (
               <WMSTileLayer
               layers="geonode:_01_obs_nanay_incendios_2020"
               version="1.1.0"
               opacity={1}
               transparent
               url="https://maps.oraotca.org/geoserver/geonode/wms?"
               srs="EPSG:4326"
               format="image/png"
             />

      )}

        {selectedMonitoreo.includes('Incendio') && (
               <WMSTileLayer
               layers="geonode:_01_obs_nanay_incendios_2021"
               version="1.1.0"
               opacity={1}
               transparent
               url="https://maps.oraotca.org/geoserver/geonode/wms?"
               srs="EPSG:4326"
               format="image/png"
             />

      )}
              {selectedMonitoreo.includes('Incendio') && (
               <WMSTileLayer
               layers="geonode:_01_obs_nanay_incendios_2022"
               version="1.1.0"
               opacity={1}
               transparent
               url="https://maps.oraotca.org/geoserver/geonode/wms?"
               srs="EPSG:4326"
               format="image/png"
             />

      )}
              {selectedMonitoreo.includes('Incendio') && (
               <WMSTileLayer
               layers="geonode:_01_obs_nanay_incendios_2023"
               version="1.1.0"
               opacity={1}
               transparent
               url="https://maps.oraotca.org/geoserver/geonode/wms?"
               srs="EPSG:4326"
               format="image/png"
             />

      )}
          
              {selectedMonitoreo.includes('Precipitación') && (
               <WMSTileLayer
               layers="geonode:_01_obs_nanay_precipitacion_2023_09_30_2"
               version="1.1.0"
               opacity={1}
               transparent
               url="https://maps.oraotca.org/geoserver/geonode/wms?"
               srs="EPSG:4326"
               format="image/png"
             />

      )}
                    {selectedMonitoreo.includes('Temperatura') && (
               <WMSTileLayer
               layers="geonode:_01_obs_nanay_temperatura_2019"
               version="1.1.0"
               opacity={1}
               transparent
               url="https://maps.oraotca.org/geoserver/geonode/wms?"
               srs="EPSG:4326"
               format="image/png"
             />

      )}
                          {selectedMonitoreo.includes('Cobertura forestal') && (
               <WMSTileLayer
               layers="geonode:obs_nanay_cobertura_forestal_2000"
               version="1.1.0"
               opacity={1}
               transparent
               url="https://maps.oraotca.org/geoserver/geonode/wms?"
               srs="EPSG:4326"
               format="image/png"
             />

      )}
{/*                           {selectedMonitoreo.includes('Temperatura') && (
               <WMSTileLayer
               layers="geonode:_01_obs_nanay_temperatura_2020"
               version="1.1.0"
               opacity={1}
               transparent
               url="https://maps.oraotca.org/geoserver/geonode/wms?"
               srs="EPSG:4326"
               format="image/png"
             />

      )}
                          {selectedMonitoreo.includes('Temperatura') && (
               <WMSTileLayer
               layers="geonode:_01_obs_nanay_temperatura_2021"
               version="1.1.0"
               opacity={1}
               transparent
               url="https://maps.oraotca.org/geoserver/geonode/wms?"
               srs="EPSG:4326"
               format="image/png"
             />

      )}
                          {selectedMonitoreo.includes('Temperatura') && (
               <WMSTileLayer
               layers="geonode:_01_obs_nanay_temperatura_2022"
               version="1.1.0"
               opacity={1}
               transparent
               url="https://maps.oraotca.org/geoserver/geonode/wms?"
               srs="EPSG:4326"
               format="image/png"
             />

      )}
                          {selectedMonitoreo.includes('Temperatura') && (
               <WMSTileLayer
               layers="geonode:_01_obs_nanay_temperatura_2023"
               version="1.1.0"
               opacity={1}
               transparent
               url="https://maps.oraotca.org/geoserver/geonode/wms?"
               srs="EPSG:4326"
               format="image/png"
             />

      )} */}

    </LayersControl.BaseLayer>
  ))}
</LayersControl>



                          {/* SHAE MUNDE */}
            {coordinatesmundo.map((multiPolygon, multiIndex) =>
                  multiPolygon.map((polygon, polyIndex) => (
                    <Polygon
                      key={`${multiIndex}-${polyIndex}`}
                      positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                      fillColor={
                        multiPolygon[0].properties && multiPolygon[0].properties.NOMBRE_2 === "Completo"
                          ? "black" // Polígono con nombre "Completo" en negro
                          : "black" // Resto de los polígonos en negro
                      }
                      fillOpacity={0.6} // Valor de transparencia (0 es completamente transparente, 1 es opaco)
                      weight={0.2}
                      dashArray="5, 10"
                    />
                  ))
                )}

            {/*SHAPE CUENCA  */}
        {Array.isArray(coordinates) &&
              coordinates.map((multiPolygon, multiIndex) =>
                multiPolygon.map((polygon, polyIndex) => (
                  <Polygon
                    key={`${multiIndex}-${polyIndex}`}
                    positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                    fillColor="transparent"
                    color="red"
                    weight={2}
                    dashArray="5, 10"
 

                  />
                ))
              )}




            {/* SHAPE RIOS */}
            {selectedShapes.includes('Rios') && Array.isArray(coordinatesrios) && 
              coordinatesrios.flatMap((multiPolygon, multiIndex) =>
                multiPolygon.map((polygon, polyIndex) => (
                  // Render the Polygon
                  <Polygon
                    key={`${multiIndex}-${polyIndex}`} // Unique key for each polygon
                    positions={polygon[0].map(([lng, lat]) => [lat, lng])} // Convert the positions

                    // Polygon styling
                    color="#007B9E"          // Set the line color of the polygon to dark cyan
                    fillColor="#007B9E"     // Set the fill color of the polygon
                    fillOpacity={0.5}       // Set the fill opacity
                  />
                ))
              )
            }          
            {/*SHAPE LAGOS */}
            {selectedShapes.includes('Lagunas') && Array.isArray(coordinateslagos) &&
              coordinateslagos.map((multiPolygon, multiIndex) =>
                multiPolygon.map((polygon, polyIndex) => (
                  <Polygon
                    key={`${multiIndex}-${polyIndex}`}
                    positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                    color="#7FFFD4" 
                    fillColor="#7FFFD4" 
                    fillOpacity={0.5}
                  />
                ))
            )}

            {/* SHAPE DISTRITO DE MAYNAS */}
            {Array.isArray(coordinatesm) && coordinatesm.map((multiPolygon, multiIndex) => 
              (selectedDistricts.includes('TODO') || selectedDistricts.includes(maynas.features[multiIndex].properties.NOMBDIST)) 
              && Array.isArray(multiPolygon) 
              && multiPolygon.map((polygon, polyIndex) => 
                  Array.isArray(polygon) && 
                  <Polygon
                  key={`${multiIndex}-${polyIndex}`}
                  positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                  color={districtColors[multiIndex % districtColors.length]}
                  fillColor="transparent"
                  weight={0.9}
                >
                  <Tooltip permanent>{maynas.features[multiIndex].properties.NOMBDIST}</Tooltip>
                </Polygon>
              )
            )}

            {/* SHAPE COMUNIDADES MINERAS */}
            {selectedMineria.includes('Concesión otorgada') && Array.isArray(coordinatesminera) &&
              coordinatesminera.map((multiPolygon, multiIndex) =>
                multiPolygon.map((polygon, polyIndex) => (
                  <Polygon
                    key={`${multiIndex}-${polyIndex}`}
                    positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                    color="yellow" 
                    fillColor="red"
                    fillOpacity={0.5}
                  />
                ))
            )}


            {/* PUNTOS ESTABLECIMIENTOS DE SALUD */}
            {selectedInfrapublica.includes('Postas médicas') && Array.isArray(coordinatesSalud) &&
              coordinatesSalud.map((point, pointIndex) => {
                const { coordinates } = point;
                const { Nombre, Clasificac, Tipo } = point;
                const lat = coordinates[1];
                const lng = coordinates[0];
                const size = 0.005; 

                return (
                  <Rectangle
                    key={`rectangle-${pointIndex}`}
                    bounds={[
                      [lat - size / 2, lng - size / 2],
                      [lat + size / 2, lng + size / 2],
                    ]}
                    color="yellow" // Color del borde
                    fillColor="skyblue" // Color del relleno
                    fillOpacity={0.9} 
                    weight={0.9}// Opacidad del relleno
                  >
                    <Popup>
                      <div>
                        <strong>ESTABLECIMIENTOS DE SALUD</strong>
                        <p>Nombre: {Nombre}</p>
                        <p>Clasificación: {Clasificac}</p>
                        <p>Tipo: {Tipo}</p>
                      </div>
                    </Popup>
                  </Rectangle>
                );

              })
            }

            {/* ACTIVIDAD MINERA */}

            {selectedMineria.includes('Dragas intervenidas') && Array.isArray(coordinatesMinera) &&
                coordinatesMinera.map((point, pointIndex) => {
                  const { coordinates } = point;
                  const lat = coordinates[1];
                  const lng = coordinates[0];
                  const sizeInPixels = 20; // Tamaño fijo en píxeles (ajusta según tus necesidades)

                  // Calcula el tamaño en coordenadas de latitud y longitud
                  const latDelta = sizeInPixels * 0.0001;
                  const lngDelta = sizeInPixels * 0.0001;

                  // Define las coordenadas para el signo + con tamaño fijo
                  const plusCoordinates = [
                    [[lat - latDelta, lng], [lat + latDelta, lng]], // Línea horizontal
                    [[lat, lng - lngDelta], [lat, lng + lngDelta]], // Línea vertical (corregido)
                  ];

                  return (
                    <Polygon
                      key={`polygon-plus-${pointIndex}`}
                      positions={plusCoordinates}
                      color="red" // Color del borde
                      weight={4} // Grosor del borde
                    />
                  );
                })
            }
            {/* Colegios */}
            {selectedInfrapublica.includes('Instituciones educativas') && Array.isArray(coordinatesc) &&
              coordinatesc.map((point, pointIndex) => {
                const { coordinates } = point;
                const { NOMCPSIG } = point;
                const lat = coordinates[1];
                const lng = coordinates[0];
                const size = 0.005; 
                

                return (
                  <Rectangle
                    key={`rectangle-${pointIndex}`}
                    bounds={[
                      [lat - size / 2, lng - size / 2],
                      [lat + size / 2, lng + size / 2],
                    ]}
                    color="yellow" // Color del borde
                    fillColor="blue" // Color del relleno
                    fillOpacity={0.8} 
                    weight={0.9}// Opacidad del relleno
                  >
                                        <Popup>
                      <div>
                        <strong>Colegio</strong>
                        <p>Nombre: {NOMCPSIG}</p>

                      </div>
                    </Popup>
                    </Rectangle>
                )


              })
            }
            {/* ANP'S */}
            {selectedExtra.includes('ANP') && Array.isArray(coordinatesanprivada) &&
              coordinatesanprivada.map((multiPolygon, multiIndex) =>
                multiPolygon.map((polygon, polyIndex) => (
                  <Polygon
                    key={`${multiIndex}-${polyIndex}`}
                    positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                    color="#7FFFD4" 
                    fillColor="#7FFFD4" 
                    fillOpacity={0.1}
                    weight={0.8}
                  >
                    <Tooltip>{geojsonAnprivada.features[multiIndex].properties.acp_nomb}</Tooltip>
                  </Polygon>
                ))
            )}
            {selectedExtra.includes('ANP') && Array.isArray(coordinatesanp1) &&
              coordinatesanp1.map((multiPolygon, multiIndex) =>
                multiPolygon.map((polygon, polyIndex) => (
                  <Polygon
                    key={`${multiIndex}-${polyIndex}`}
                    positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                    color="#7FFFD4" 
                    fillColor="#7FFFD4" 
                    fillOpacity={0.1}
                    weight={0.8}
                  >
                    <Tooltip >{anp1.features[multiIndex].properties.anp_nomb}</Tooltip>
                    </Polygon>
                ))
            )}
            {selectedExtra.includes('ANP') && Array.isArray(coordinatesanp3) &&
              coordinatesanp3.map((multiPolygon, multiIndex) =>
                multiPolygon.map((polygon, polyIndex) => (
                  <Polygon
                    key={`${multiIndex}-${polyIndex}`}
                    positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                    color="#7FFFD4" 
                    fillColor="#7FFFD4" 
                    fillOpacity={0.1}
                    weight={0.8}
                  >
                    <Tooltip >{anp3.features[multiIndex].properties.acr_nomb}</Tooltip>
                    </Polygon>
                ))
            )}
            {selectedExtra.includes('ANP') && Array.isArray(coordinatesanp5) &&
              coordinatesanp5.map((multiPolygon, multiIndex) =>
                multiPolygon.map((polygon, polyIndex) => (
                  <Polygon
                    key={`${multiIndex}-${polyIndex}`}
                    positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                    color="#7FFFD4" 
                    fillColor="#7FFFD4" 
                    fillOpacity={0.1}
                    weight={0.8}


                  >
                    <Tooltip >{anp5.features[multiIndex].properties.acp_nomb}</Tooltip>
                    </Polygon>                  
                ))                
            )}

            {/* CENTROS POBLANDOS */}

            {selectedExtra.includes('Centros poblados') && Array.isArray(coordinatescentrop1) &&
              coordinatescentrop1.map((multiPolygon, multiIndex) =>
                multiPolygon.map((polygon, polyIndex) => (
                  <Polygon
                    key={`${multiIndex}-${polyIndex}`}
                    positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                    color="#7FFFD4" 
                    fillColor="#7FFFD4" 
                    fillOpacity={0.1}
                    weight={0.8}


                  >
                    <Tooltip >{centrop1.features[multiIndex].properties.nombre}</Tooltip>
                    </Polygon>

                  
                ))

                
            )}
                        {selectedExtra.includes('Centros poblados') && Array.isArray(coordinatescentrop2) &&
              coordinatescentrop2.map((multiPolygon, multiIndex) =>
                multiPolygon.map((polygon, polyIndex) => (
                  <Polygon
                    key={`${multiIndex}-${polyIndex}`}
                    positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                    color="#7FFFD4" 
                    fillColor="#7FFFD4" 
                    fillOpacity={0.1}
                    weight={0.8}


                  >
                    <Tooltip >{centrop2.features[multiIndex].properties.nombre}</Tooltip>
                    </Polygon>

                  
                ))

                
            )}

            {/* MINERIA ILEGAL */}
            {selectedMineria.includes('Comunidades con actividad') &&
                Array.isArray(coordinatesMineriaIlegal) &&
                coordinatesMineriaIlegal.map((point, pointIndex) => {
                  const { coordinates } = point;
                  const { Nombre } = point;
                  const lat = coordinates[1];
                  const lng = coordinates[0];
                  const size = 0.005; // Tamaño del cuadrado (ajusta según tus necesidades)

                  return (
                    <Rectangle
                      key={`rectangle-${pointIndex}`}
                      bounds={[
                        [lat - size / 2, lng - size / 2],
                        [lat + size / 2, lng + size / 2],
                      ]}
                      color="yellow" // Color del borde
                      fillColor="red" // Color del relleno
                      fillOpacity={0.8} // Opacidad del relleno
                    >

                    <Popup>
                      <div>
                        <strong>Comunidad con actividad</strong>
                        <p>Comunidad: {Nombre}</p>
                        

                      </div>
                    </Popup>

                      </Rectangle>
                  );
                })
            }
            {/* DELITOS */}
            {selectedDelitos.includes('Homicidios') && Array.isArray(coordinatesd1) &&
              coordinatesd1.map((point, pointIndex) => {
                const { coordinates } = point;
                const { Comunidad, Descripcion } = point;


                return (
                  <Marker
                    key={`salud-marker-${pointIndex}`}
                    position={[coordinates[1], coordinates[0]]}
                    icon={homiicon}
                  >
                    <Popup>
                      <div>
                        <strong>Homicicios</strong>
                        <p>Comunidad: {Comunidad}</p>
                        <p>Descripción: {Descripcion}</p>

                      </div>
                    </Popup>

                  </Marker>
                );
              })
            }
            {selectedDelitos.includes('Violaciones') && Array.isArray(coordinatesd2) &&
              coordinatesd2.map((point, pointIndex) => {
                const { coordinates } = point;
                const { Comunidad, Descripcion } = point;


                return (
                  <Marker
                    key={`salud-marker-${pointIndex}`}
                    position={[coordinates[1], coordinates[0]]}
                    icon={violicon}
                  >
                                        <Popup>
                      <div>
                        <strong>Violaciones</strong>
                        <p>Comunidad: {Comunidad}</p>
                        

                      </div>
                    </Popup>

                  </Marker>
                );
              })
            }

            {selectedDelitos.includes('Trata de personas') && Array.isArray(coordinatesd3) &&
              coordinatesd3.map((point, pointIndex) => {
                const { coordinates } = point;
                const { Comunidad, Descripcion } = point;


                return (
                  <Marker
                    key={`salud-marker-${pointIndex}`}
                    position={[coordinates[1], coordinates[0]]}
                    icon={trataicon}
                  >

                    <Popup>
                      <div>
                        <strong>Trata de personas</strong>
                        <p>Comunidad: {Comunidad}</p>
                        

                      </div>
                    </Popup>

                  </Marker>
                );
              })
            }

            {selectedDelitos.includes('Tocamientos') && Array.isArray(coordinatesd4) &&
              coordinatesd4.map((point, pointIndex) => {
                const { coordinates } = point;
                const { Comunidad, Descripcion } = point;


                return (
                  <Marker
                    key={`salud-marker-${pointIndex}`}
                    position={[coordinates[1], coordinates[0]]}
                    icon={tocaicon}
                  >

                    <Popup>
                      <div>
                        <strong>Tocamientos</strong>
                        <p>Comunidad: {Comunidad}</p>
                        

                      </div>
                    </Popup>

                  </Marker>
                );
              })
            }






            



        


            </MapContainer>
        </div>
    </div>


   
      


      
    </>
  );
};

export default Visorcuencananay;
