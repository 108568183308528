import DataTable from "react-data-table-component";
import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  CircleMarker,
  Popup,
  Polygon,
  LayersControl,
} from "react-leaflet";
import axios from "axios";
import Header from "./Components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "leaflet/dist/leaflet.css";
import L, { Icon } from "leaflet";
import Iconomap from "./Components/img/marcadorrojo.png";
import geojsonData from "./Components/img/maynas.json";
import "./Source/visor.css";
import VisitorInfo from "./Info";
import "leaflet/dist/leaflet.css";
import "flowbite/dist/flowbite.css";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

const center = {
  lat: -3.7572821,
  lng: -73.2710273,
};

const treeIcon = new Icon({
  iconUrl: Iconomap,
  iconSize: [35, 41],
  iconAnchor: [12, 41],
});

const districtColors = [
  "#DFFFD9",
  "#AEE4AE",
  "#7ECC8E",
  "#57D957",
  "#30C030",
  "#0A7B0D",
];

const apiUrl = process.env.REACT_APP_API_URL;
const debugMode = process.env.REACT_APP_DEBUG_MODE === "true";
const apiMaps = process.env.REACT_APP_GOOGLE_MAPS;

const VisorMaynas = () => {
  const googleApiKey = "AIzaSyCG90SDbD-bJ4dMB6OtebSbAeRs5haolq0";
  const mapStyles = {
    Aubergine: require("./Stylemaps/aubergine-map-style.json"),
    Dark: require("./Stylemaps/dark-map-style.json"),
    Retro: require("./Stylemaps/retro-map-style.json"),
    Night: require("./Stylemaps/night-map-style.json"),
    Estandar: require("./Stylemaps/standard-map-style.json"),
  };

  const [selectedStyle, setSelectedStyle] = useState("Retro");

  const [trees, setTrees] = useState([]);
  const [search, setSearch] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState(["TODO"]);

  const DistrictControl = ({ selectedDistricts, onDistrictChange }) => {
    return (
      <div style={{ background: "white", padding: "10px" }}>
        <p>
          <b>Límites distritales: </b>
        </p>
        {[
          "TODO",
          "BELEN",
          "SAN JUAN BAUTISTA",
          "IQUITOS",
          "PUNCHANA",
          "LIMITE CONCURSO",
        ].map((district) => (
          <div key={district}>
            <label>
              <input
                type="checkbox"
                name={district}
                onChange={(e) =>
                  onDistrictChange(e.target.name, e.target.checked)
                }
                checked={selectedDistricts.includes(district)}
              />
              {district}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const handleDistrictChange = (district, isChecked) => {
    if (isChecked) {
      setSelectedDistricts((prev) => [...prev, district]);
    } else {
      setSelectedDistricts((prev) => prev.filter((d) => d !== district));
    }
  };

  useEffect(() => {
    if (
      geojsonData.features &&
      Array.isArray(geojsonData.features) &&
      geojsonData.features.length > 0
    ) {
      const allPolygons = geojsonData.features
        .filter(
          (feature) =>
            feature.geometry && feature.geometry.type === "MultiPolygon"
        )
        .map((feature) => feature.geometry.coordinates);
      if (Array.isArray(allPolygons) && allPolygons.length > 0) {
        setCoordinates(allPolygons);
      } else {
        setCoordinates([]);
      }
    } else {
      setCoordinates([]);
    }
  }, []);

  useEffect(() => {
    const fetchTrees = async () => {
      try {
        const response = await axios.get(apiUrl + "trees");
        setTrees(response.data.trees);
      } catch (error) {
        console.error("Error fetching trees", error);
      }
    };
    fetchTrees();
  }, []);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredTrees = search
    ? trees.filter(
        (tree) =>
          tree.reg_txt_scientificname
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          tree.reg_txt_commonnames
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          tree.reg_txt_identifier?.toLowerCase().includes(search.toLowerCase())
      )
    : trees;

  const columns = [
    {
      name: "Nombre Científico",
      selector: "reg_txt_scientificname",
      sortable: true,
    },
    {
      name: "Nombre Común",
      selector: "reg_txt_commonnames",
      sortable: true,
    },
    {
      name: "Identificador",
      selector: "reg_txt_identifier",
      sortable: true,
    },
    {
      name: "Fecha de Creación",
      selector: "created_at",
      sortable: true,
    },
    {
      name: "Acción",
      sortable: false,
      cell: (row) => (
        <a
          href={`/ficha-arbol/${row.reg_int_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            className="btn btn-success"
            style={{
              padding: "10px 20px",
              borderRadius: "8px",
            }}
          >
          Ver Ficha
          </button>
        </a>
      ),
    },
  ];

  return (
    <>
      <Header />
      <VisitorInfo page="VISOR DE ARBOLES REGISTRADOS"></VisitorInfo>
      <div className="d-flex flex-column align-items-center mt-5">
        <div className="input-group mb-3" style={{ width: "500px" }}>
          <input
            type="text"
            className="form-control"
            placeholder="Buscar por nombre científico, común o identificador"
            value={search}
            onChange={handleSearchChange}
          />
        </div>
        {search && filteredTrees.length === 0 && (
          <p className="mb-3">
            No se encontraron resultados para su búsqueda. {apiUrl} snnkdn
          </p>
        )}

        <MapContainer
          center={center}
          zoom={13}
          style={{ width: "100%", height: "100vh" }}
        >
          <LayersControl position="topright">
            {Object.keys(mapStyles).map((style) => (
              <LayersControl.BaseLayer
                key={style}
                checked={style === selectedStyle}
                name={style + " Map"}
              >
                <ReactLeafletGoogleLayer
                  apiKey={googleApiKey}
                  type="roadmap"
                  styles={mapStyles[style]}
                />
              </LayersControl.BaseLayer>
            ))}
          </LayersControl>

          <div className="control" style={{marginTop:150}}>
            <DistrictControl
              selectedDistricts={selectedDistricts}
              onDistrictChange={handleDistrictChange}
            />
          </div>

          {filteredTrees.map((tree) => (
            <CircleMarker
              key={tree.reg_int_id}
              center={[
                parseFloat(tree.reg_txt_lat),
                parseFloat(tree.reg_txt_long),
              ]}
              radius={5} // Puedes ajustar el tamaño del círculo según tus preferencias
              //color="red" // Puedes ajustar el color del círculo según tus preferencias
              fillColor={
                tree.reg_txt_typetree === "1"
                  ? "green" // Palmera
                  : tree.reg_txt_typetree === "2"
                  ? "red" // Maderable
                  : tree.reg_txt_typetree === "3"
                  ? "blue" // Frutal
                  : tree.reg_txt_typetree === "4"
                  ? "yellow" // Frutal
                  : "green" // Por defecto (amarillo) si no hay coincidencia
              } // Establece el color de fondo del círculo
              color="yellow" // Establece el color del contorno del círculo
              weight={0.4} // Ajusta el grosor del contorno
              fillOpacity={5}
            >
              <Popup>
                <div>
                  <h2>
                    <b>{tree.reg_txt_scientificname}</b>
                  </h2>
                  <p>
                    <b>Nombre Común:</b> {tree.reg_txt_commonnames}
                  </p>
                  <p>
                    <b>Identificador:</b> {tree.reg_txt_identifier}
                  </p>
                  <p>
                    <b>Fecha de Creación:</b> {tree.reg_txt_datecreation}
                  </p>
                  <p>
                    <a
                      href={`/ficha-arbol/${tree.reg_int_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        className="btn btn-success"
                        style={{
                          padding: "10px 20px",
                          borderRadius: "8px",
                        }}
                      >
                        Ver Ficha
                      </button>
                    </a>
                  </p>
                </div>
              </Popup>
            </CircleMarker>
          ))}
          {Array.isArray(coordinates) &&
            coordinates.map(
              (multiPolygon, multiIndex) =>
                (selectedDistricts.includes("TODO") ||
                  selectedDistricts.includes(
                    geojsonData.features[multiIndex].properties.NOMBDIST
                  )) &&
                Array.isArray(multiPolygon) &&
                multiPolygon.map(
                  (polygon, polyIndex) =>
                    Array.isArray(polygon) && (
                      <Polygon
                        key={`${multiIndex}-${polyIndex}`}
                        positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                        color={
                          districtColors[multiIndex % districtColors.length]
                        }
                        fillColor="transparent"
                      />
                    )
                )
            )}
        </MapContainer>

        <div className="mt-4" style={{ width: "100%" }}>
          <DataTable
            title="Lista de Árboles"
            columns={columns}
            data={filteredTrees}
            pagination
            highlightOnHover
          />
        </div>
      </div>
    </>
  );
};

export default VisorMaynas;
