import DataTable from 'react-data-table-component';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polygon } from 'react-leaflet';
import axios from 'axios';
import Header from './Components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import L, { Icon } from 'leaflet';
import Iconomap from './Components/img/marcadorrojo.png';
import './Source/visor.css';
import geojsonData from './Components/img/maynasc.json';

const apiUrl = process.env.REACT_APP_API_URL;
const apiMaps = process.env.REACT_APP_GOOGLE_MAPS;

const center = {
  lat: -3.7572821,
  lng: -73.2710273,
};

const treeIcon = new Icon({
  iconUrl: Iconomap,
  iconSize: [35, 41],
  iconAnchor: [12, 41],
});

const districtColors = [
  '#DFFFD9',
  '#AEE4AE',
  '#7ECC8E',
  '#57D957',
  '#30C030',
  '#0A7B0D'
];

const Visor = () => {
  const [trees, setTrees] = useState([]);
  const [search, setSearch] = useState('');
  const [coordinates, setCoordinates] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState(['TODO']);



  const [districtNames, setDistrictNames] = useState(['TODO']);

  useEffect(() => {
    if (geojsonData.features && Array.isArray(geojsonData.features)) {
      const uniqueDistricts = Array.from(
        new Set(geojsonData.features.map(feature => feature.properties.NOMBDIST))
      );
      setDistrictNames(prevState => ['TODO', ...uniqueDistricts]);
    }
  }, []);


  const DistrictControl = ({ selectedDistricts, onDistrictChange }) => {
    return (
      <div style={{ background: 'white', padding: '10px' }}>
        <p><b>Límites distritales: </b></p>
        {districtNames.map(district => (
          <div key={district}>
            <label>
              <input
                type="checkbox"
                name={district}
                onChange={e => onDistrictChange(e.target.name, e.target.checked)}
                checked={selectedDistricts.includes(district)}
              />
              {district}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const handleDistrictChange = (district, isChecked) => {
    if (district === 'TODO' && isChecked) {
      setSelectedDistricts(['TODO']);
    } else if (isChecked) {
      setSelectedDistricts(prev => [...prev.filter(d => d !== 'TODO'), district]);
    } else {
      setSelectedDistricts(prev => prev.filter(d => d !== district));
    }
  };

  useEffect(() => {
    if (geojsonData.features && Array.isArray(geojsonData.features) && geojsonData.features.length > 0) {
      const allPolygons = geojsonData.features.filter(feature => feature.geometry && feature.geometry.type === 'MultiPolygon')
          .map(feature => feature.geometry.coordinates);
      if (Array.isArray(allPolygons) && allPolygons.length > 0) {
          setCoordinates(allPolygons);
      } else {
          setCoordinates([]);
      }
    } else {
      setCoordinates([]);
    }
  }, []);

  useEffect(() => {
    const fetchTrees = async () => {
      try {
        const response = await axios.get(apiUrl+"trees");
        setTrees(response.data.trees);
      } catch (error) {
        console.error("Error fetching trees", error);
      }
    };
    fetchTrees();
  }, []);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredTrees = search
    ? trees.filter(tree =>
        tree.reg_txt_scientificname?.toLowerCase().includes(search.toLowerCase()) ||
        tree.reg_txt_commonnames?.toLowerCase().includes(search.toLowerCase()) ||
        tree.reg_txt_identifier?.toLowerCase().includes(search.toLowerCase())
      )
    : trees;

  const columns = [
    {
      name: 'Nombre Científico',
      selector: 'reg_txt_scientificname',
      sortable: true,
    },
    {
      name: 'Nombre Común',
      selector: 'reg_txt_commonnames',
      sortable: true,
    },
    {
      name: 'Identificador',
      selector: 'reg_txt_identifier',
      sortable: true,
    },
    {
      name: 'Fecha de Creación',
      selector: 'created_at',
      sortable: true,
    },
    {
      name: 'Acción',
      sortable: false,
      cell: row => (
        <a href={`/ficha-arbol/${row.reg_int_id}`} target="_blank" rel="noopener noreferrer">
          <button
            className="btn btn-success"
            style={{ 
              padding: '10px 20px',
              borderRadius: '8px',
            }}
          >
            Ver Ficha
          </button>
        </a>
      ),
    },
  ];

  return (
    <>
      <Header />
      <div className="d-flex flex-column align-items-center mt-5">
        <div className="input-group mb-3" style={{ width: '500px' }}> 
          <input
            type="text"
            className="form-control"
            placeholder="Buscar por nombre científico, común o identificador"
            value={search}
            onChange={handleSearchChange}
          />
        </div>
        {search && filteredTrees.length === 0 && (
          <p className="mb-3">No se encontraron resultados para su búsqueda.</p>
        )}

        <MapContainer center={center} zoom={13} style={{ width: '100%', height: '650px' }}>
          <div className="control">
            <DistrictControl 
              selectedDistricts={selectedDistricts}
              onDistrictChange={handleDistrictChange}
            />
          </div>
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution='&copy; <a href="https://www.esri.com/">Esri</a>'
          />
          {filteredTrees.map(tree => (
            <Marker
              key={tree.reg_int_id}
              position={[parseFloat(tree.reg_txt_lat), parseFloat(tree.reg_txt_long)]}
              icon={treeIcon}
            >
              <Popup>
                <div>
                  <h2>{tree.reg_txt_scientificname}</h2>
                  <p>Nombre Común: {tree.reg_txt_commonnames}</p>
                  <p>Identificador: {tree.reg_txt_identifier}</p>
                  <p>Fecha de Creación: {tree.created_at}</p>
                  <p>
                    <a 
                      href={`/ficha-arbol/${tree.reg_int_id}`}
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <button 
                        className="btn btn-success"
                        style={{ 
                          padding: '10px 20px',
                          borderRadius: '8px',
                        }}
                      >
                        Ver Ficha
                      </button>
                    </a>
                  </p>
                </div>
              </Popup>
            </Marker>
          ))}
          {Array.isArray(coordinates) && coordinates.map((multiPolygon, multiIndex) => 
            (selectedDistricts.includes('TODO') || selectedDistricts.includes(geojsonData.features[multiIndex].properties.NOMBDIST)) 
            && Array.isArray(multiPolygon) 
            && multiPolygon.map((polygon, polyIndex) => 
                Array.isArray(polygon) && 
                <Polygon 
                    key={`${multiIndex}-${polyIndex}`} 
                    positions={polygon[0].map(([lng, lat]) => [lat, lng])}
                    color={districtColors[multiIndex % districtColors.length]}
                    fillColor="transparent" 
                />
            )
          )}
        </MapContainer>

        <div className="mt-4" style={{ width: '100%' }}> 
          <DataTable
            title="Lista de Árboles"
            columns={columns}
            data={filteredTrees}
            pagination
            highlightOnHover
          />
        </div>
      </div>
    </>
  );
};

export default Visor;
