import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  MapContainer,
  LayersControl,
  CircleMarker,
  Popup,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import "leaflet/dist/leaflet.css";
import { Col, Container, Row } from "react-bootstrap";
import "flowbite/dist/flowbite.css";
import MapLegend from "./MapLegend";
import "./../Source/StyleLegend.css";
import "../Components/LOGO2.png";
import ClipLoader from "react-spinners/ClipLoader";

const apiUrl = process.env.REACT_APP_API_URL;
const baseURL = "https://identiarbol.org/identiarbolbackend/public/";

const Album2 = () => {
  const { regIntId } = useParams();
  const [userData, setUserData] = useState({});
  const [allImages, setAllImages] = useState([]);
  const [visibleImages, setVisibleImages] = useState([]);
  const [visibleCount, setVisibleCount] = useState(20);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [coordinate, setCoordinate] = useState([]);
  const [selectedDescriptionFilter, setSelectedDescriptionFilter] =
    useState("All");
  const [showAllPoints, setShowAllPoints] = useState(true);
  const [countImage, setCountImage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const center = {
    lat: -3.7572821,
    lng: -73.2710273,
  };

  const updateUserData = (data) => {
    if (data.users.length > 0) {
      setUserData(data.users[0]);
    }
  };

  const updateImagesData = (data) => {
    if (data.images) {
      setAllImages(data.images);
      setVisibleImages(data.images.slice(0, visibleCount));
      setCountImage(data.images.length);
    }
  };
  const updateCoordinatesData = (data) => {
    if (data.coordinate) {
      setCoordinate(data.coordinate);
    }
  };
  const [selectedStyle, setSelectedStyle] = useState("Aubergine");

  const googleApiKey = "AIzaSyCBkSTyfrkxXe5LiYLZZTRtKvQqSHt289Y";

  const mapStyles = {
    Aubergine: require("../Stylemaps/aubergine-map-style.json"),
    Dark: require("../Stylemaps/dark-map-style.json"),
    Retro: require("../Stylemaps/retro-map-style.json"),
    Night: require("../Stylemaps/night-map-style.json"),
    Estandar: require("../Stylemaps/standard-map-style.json"),
  };

  const fetchData = async () => {
    try {
      const userResponse = await axios.get(
        `${apiUrl}user/useralbum/${regIntId}`
      );
      updateUserData(userResponse.data);
      console.log(userResponse.data.users);

      const imagesResponse = await axios.get(`${apiUrl}userimage/${regIntId}`);
      updateImagesData(imagesResponse.data);

      const coordinatesResponse = await axios.get(
        `${apiUrl}treecoordinate/${regIntId}?descriptionFilter=${selectedDescriptionFilter}`
      );
      updateCoordinatesData(coordinatesResponse.data);
      console.log(coordinatesResponse.data.coordinate);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [visibleCount]);

  const loadMoreImages = () => {
    setIsLoading(true);
    setTimeout(() => {
      setVisibleCount((prevCount) => prevCount + 20);
      setIsLoading(false);
    }, 10000);
    // setVisibleCount((prevCount) => prevCount + 20);
  };

  const handleMouseOver = (image) => {
    setHoveredImage(image);
  };

  const handleMouseOut = () => {
    setHoveredImage(null);
  };

  const openImageInNewTab = (image) => {
    const regIntId = image.reg_int_id;
    window.open(`/editor-album/${regIntId}`, "_blank");
  };
  const reloadPage = () => window.location.reload();

  return (
    <div style={{ backgroundColor: "#a8ff9a", padding: "20px" }}>
      <div
        style={{
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <Container style={{ weight: "100%", padding: "15px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={require("../Components/LOGO2.png")} // Asegúrate de proporcionar la ruta correcta
              alt="Logo"
              style={{ width: "50px", height: "50px", marginRight: "10px" }}
            />
            <h1 style={{ color: "green", fontSize: "25px" }}>
              <strong>ALBUM - IDENTIARBOL </strong>
            </h1>
          </div>

          <Row>
            <Col
              xs={3}
              className="d-flex justify-content-start align-items-start"
            >
              <div
                style={{
                  width: "100%",
                  margin: "10px",
                  textAlign: "start",
                  color: "#000000",
                  paddingBlock: "50px",
                }}
              >
                <h2
                  style={{
                    color: "#00cd00",
                    fontSize: "18px",
                    marginBottom: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Datos del Usuario
                </h2>
                <div style={{ marginBottom: "10px" }}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "100px",
                      fontWeight: "bold",
                    }}
                  >
                    ID Usuario:
                  </span>
                  <span>{userData.use_int_id}</span>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "100px",
                      fontWeight: "bold",
                    }}
                  >
                    Nombres:
                  </span>
                  <span style={{ textTransform: "uppercase" }}>
                    {userData.use_txt_name}
                  </span>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "100px",
                      fontWeight: "bold",
                    }}
                  >
                    Apellidos:
                  </span>
                  <span style={{ textTransform: "uppercase" }}>
                    {userData.use_txt_lastname}
                  </span>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "100px",
                      fontWeight: "bold",
                    }}
                  >
                    DNI:
                  </span>
                  <span>{userData.use_txt_dni}</span>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "100px",
                      fontWeight: "bold",
                    }}
                  >
                    Colegio:
                  </span>
                  <span style={{ textTransform: "uppercase" }}>
                    {userData.use_txt_school}
                  </span>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "100px",
                      fontWeight: "bold",
                    }}
                  >
                    Distrito:
                  </span>
                  <span style={{ textTransform: "uppercase" }}>
                    {userData.use_txt_district}
                  </span>
                </div>

                <div style={{ marginBottom: "10px" }}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "100px",
                      fontWeight: "bold",
                    }}
                  >
                    Cantidad de árboles:
                  </span>
                  <span style={{ textTransform: "uppercase" }}>
                    {countImage}
                  </span>
                </div>
                <button
                  onClick={reloadPage}
                  style={{
                    backgroundColor: "#4CAF50",
                    color: "white",
                    height: "35px",
                    fontWeight: "bold",
                  }}
                >
                  Actualizar Página 🗘
                </button>
              </div>
            </Col>
            <Col xs={9}>
              <div>
                <br />
                <h4 style={{ color: "#00cd00" }}>
                  <strong>Ubicación de los árboles registradas:</strong>
                </h4>

                <div className="map-legend-container">
                  <MapLegend />
                </div>

                <div
                  style={{
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    position: "relative",
                  }}
                >
                  <MapContainer
                    center={center}
                    zoom={12}
                    style={{ height: "350px", width: "100%" }}
                  >
                    <LayersControl position="topright">
                      {Object.keys(mapStyles).map((style) => (
                        <LayersControl.BaseLayer
                          key={style}
                          checked={style === selectedStyle}
                          name={style + " Map"}
                        >
                          <ReactLeafletGoogleLayer
                            apiKey={googleApiKey}
                            type="roadmap"
                            styles={mapStyles[style]}
                          />
                        </LayersControl.BaseLayer>
                      ))}
                    </LayersControl>
                    {coordinate.map(
                      (coordinate) =>
                        (((selectedDescriptionFilter === "All" ||
                          coordinate.reg_txt_typetree ===
                            selectedDescriptionFilter) &&
                          showAllPoints) ||
                          coordinate.reg_txt_typetree === "All") && (
                          <CircleMarker
                            key={coordinate.use_txt_dni}
                            center={[
                              parseFloat(coordinate.reg_txt_lat),
                              parseFloat(coordinate.reg_txt_long),
                            ]}
                            radius={6}
                            fillColor={
                              coordinate.reg_txt_typetree === "Palmera"
                                ? "green"
                                : coordinate.reg_txt_typetree ===
                                  "Palmera, Frutal"
                                ? "#a18262"
                                : coordinate.reg_txt_typetree === "Frutal"
                                ? "orange"
                                : coordinate.reg_txt_typetree === "Maderable"
                                ? "brown"
                                : coordinate.reg_txt_typetree === "Medicinal"
                                ? "blue"
                                : coordinate.reg_txt_typetree === "Ornamental"
                                ? "yellow"
                                : coordinate.reg_txt_typetree === null
                                ? "black"
                                : "black"
                            }
                            weight={0.4}
                            fillOpacity={5}
                          >
                            <Popup>
                              <div>
                                <h2>
                                  <b>Nombre Común: </b>
                                  {coordinate.reg_txt_commonnames}
                                </h2>
                                <p>
                                  <b>Nombre Científico: </b>
                                  {coordinate.reg_txt_scientificname}
                                </p>
                                <p>
                                  <b>Tipo de especie: </b>
                                  {coordinate.reg_txt_typetree}
                                </p>
                              </div>
                            </Popup>
                          </CircleMarker>
                        )
                    )}
                  </MapContainer>
                </div>
                {/* Menú desplegable y casilla de verificación */}
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    zIndex: 1000,
                    weight: "20px",
                    height: "20px",
                  }}
                >
                  <div style={{ marginBottom: "10px" }}>
                    <label style={{ padding: "5px", fontWeight: "bold" }}>
                      Filtrar por descripción:{" "}
                    </label>
                    <select
                      value={selectedDescriptionFilter}
                      onChange={(e) =>
                        setSelectedDescriptionFilter(e.target.value)
                      }
                    >
                      <option value="All">Todos</option>
                      <option value="Ornamental">Ornamental</option>
                      <option value="Maderable">Maderable</option>
                      <option value="Medicinal">Medicinal</option>
                      <option value="Frutal">Frutal</option>
                      <option value="Palmera">Palmera</option>
                      <option value="Palmera, Frutal">Palmera-Frutal</option>
                    </select>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <div style={{ weight: "100%" }}>
            <div className="image-section">
              <h2 style={{ color: "#00cd00" }}>
                <strong>Album de fotos</strong>
              </h2>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {visibleImages.map((image, index) => (
                  <div
                    key={index}
                    className="image-item"
                    style={{
                      margin: "5px",
                      position: "relative",
                      cursor: "pointer",
                    }}
                    onMouseOver={() => handleMouseOver(image)}
                    onMouseOut={handleMouseOut}
                    onClick={() => openImageInNewTab(image)}
                  >
                    <img
                      src={`${baseURL}${
                                image.ima_txt_urlimages.includes('drive.google.com')
                                  ? `https://lh3.googleusercontent.com/d/${image.ima_txt_urlimages.split('/')[5]}=w1000`
                                  : image.ima_txt_urlimages
                              }`}
                      alt={`Imagen ${index + 1}`}
                      style={{
                        width: "241px",
                        height: "380px",
                        borderRadius: "10px",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                      }}
                      loading="lazy"
                      onLoad={(e) => {
                        e.target.src = e.target.src; // Force re-render for lazy loading
                      }}
                    />
                    {hoveredImage === image && (
                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          background: "rgba(0,0,0,0.5)",
                          color: "white",
                          padding: "10px",
                          borderRadius: "5px",
                          transition: "opacity 0.5s, transform 1.0s",
                          transform:
                            hoveredImage === image
                              ? "translateY(0)"
                              : "translateY(100%)",
                          opacity: hoveredImage === image ? 1 : 0,
                        }}
                      >
                        <p>Parte: Completo</p>
                      </div>
                    )}
                    <p>
                      <strong style={{ color: "green", fontSize: "12px" }}>
                        Nombre: {image.reg_txt_commonnames.toUpperCase()}
                      </strong>
                    </p>
                  </div>
                ))}
              </div>
              {visibleCount < allImages.length && (
                <div
                  style={{
                    textAlign: "center",
                    margin: "20px",
                    color: "white",
                  }}
                >
                  {isLoading ? (
                    <ClipLoader size={50} color={"green"} loading={isLoading} />
                  ) : (
                    <button
                      onClick={loadMoreImages}
                      style={{ width: "8%", backgroundColor: "green" }}
                    >
                      Ver más
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Album2;
