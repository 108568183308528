import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

const apiUrl = process.env.REACT_APP_API_URL;
const debugMode = process.env.REACT_APP_DEBUG_MODE === 'true';

const Visitors = () => {
    const tableRef = useRef(null);
    const [visitorsData, setVisitorsData] = useState([]);

    useEffect(() => {
        // Fetch visitor data from the API
        fetch(apiUrl+'user/indexbinidentiarbol')
            .then((response) => response.json())
            .then((data) => {
                // Add a "N°" property for sequential numbering
                const numberedData = data.binnacle.map((item, index) => ({
                    ...item,
                    'N°': index + 1,
                }));
                setVisitorsData(numberedData);
            })
            .catch((error) => console.error('Error fetching visitor data:', error));
    }, []);

    useEffect(() => {
        if (visitorsData.length > 0) {
            // Initialize the DataTable with the fetched data
            const dataTable = $(tableRef.current).DataTable({
                data: visitorsData,
                columns: [
                    { data: 'N°', title: 'N°' }, // Display the numbering
                    { data: 'bin_txt_ip', title: 'IP' },
                    { data: 'bin_txt_page', title: 'Página revisada' },
                    { data: 'bin_txt_country', title: 'País' },
                    { data: 'bin_txt_regionname', title: 'Región' },
                    { data: 'bin_txt_city', title: 'Ciudad' },
                    { data: 'bin_txt_timezone', title: 'Timezone' },
                    { data: 'bin_txt_datecreation', title: 'Fecha de visita' },
                ],
                pageLength: 30, // Set the number of rows per page
            });

            return () => {
                // Destroy the DataTable when the component unmounts to prevent memory leaks
                dataTable.destroy();
            };
        }
    }, [visitorsData]);

    return (
        <div>
            <h1>Vista de Visitantes</h1>
            <table ref={tableRef} className="display" style={{ width: '100%' }} />
        </div>
    );
};

export default Visitors;
