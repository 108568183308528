import React, { useEffect, useState } from "react";
import axios from "axios";

const VisitorInfo = ({ page, origin }) => {
  const [ip, setIp] = useState(null);
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [browser, setBrowser] = useState(null);
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!dataFetched) {
        try {
          // Obtener la dirección IP del usuario
          const ipResponse = await axios.get(
            "https://api.ipify.org?format=json"
          );
          const ip = ipResponse.data.ip;
          setIp(ip);

          // Obtener información del país y ciudad utilizando ipgeolocation.io
          const apiKey = "d4059abc81c944a39e8ac1483be1c560"; // Reemplaza 'tu_clave_api' con tu clave de API
          const geoResponse = await axios.get(
            `https://api.ipgeolocation.io/ipgeo?apiKey=${apiKey}&ip=${ip}`
          );
          const data = geoResponse.data;
          setCountry(data.country_name);
          setCity(data.city);

          // Capturar información del navegador y hora del cliente
          setBrowser(navigator.userAgent);

          // Marcar que los datos se han obtenido y enviarlos a la API
          const now = new Date();
          const formattedDateTime = `${now.getFullYear()}-${String(
            now.getMonth() + 1
          ).padStart(2, "0")}-${String(now.getDate()).padStart(
            2,
            "0"
          )} ${String(now.getHours()).padStart(2, "0")}:${String(
            now.getMinutes()
          ).padStart(2, "0")}:${String(now.getSeconds()).padStart(2, "0")}`;

          const postData = {
            ser_int_id: 1,
            bin_txt_ip: ip,
            bin_txt_country: data.country_name,
            bin_txt_countrycode: data.country_code2,
            bin_txt_region: data.state_code,
            bin_txt_city: data.city,
            bin_txt_lat: data.latitude,
            bin_txt_lon: data.longitude,
            bin_txt_timezone: data.time_zone.name,
            bin_txt_isp: data.isp,
            bin_txt_org: data.organization,
            bin_txt_status: "success",
            bin_txt_regionname: data.state_prov,
            bin_txt_datecreation: formattedDateTime,
            bin_txt_page: page,
            bin_txt_origin: origin,
            bin_txt_language: navigator.language,
            bin_txt_platform: navigator.platform,
            bin_txt_useragent: navigator.userAgent,
          };

          await axios.post(
            "https://identiarbol.org/servicesbackend/public/identiarbol/user/registerbin",
            postData
          );

          setDataFetched(true); // Marcar como datos obtenidos
        } catch (error) {
          console.error("Error al obtener o enviar datos", error);
        }
      }
    };

    fetchData();

    // Configurar la actualización de la hora del cliente
    const intervalId = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => {
      clearInterval(intervalId); // Limpiar el intervalo cuando el componente se desmonta
    };
  }, [dataFetched, page, origin]);

  return <></>;
};

export default VisitorInfo;
